import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "~components/layouts/default"
import ContactUs from "~components/global/contact-us"
import SEO from "~components/common/seo"
import Container from "~components/common/container"
import Image from "gatsby-image"
import Features from "~components/common/features"
import Feature from "~components/common/feature"
import AniLink from "~components/common/anilink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ProductTemplate = ({ pageContext }) => {
  const { product } = pageContext

  return (  
    <Layout>
      <StaticQuery
        query={graphql`
          query {
            images: allFile(filter: {relativePath: {glob: "products/**/*.(png|jpg)"}}) {
              edges {
                node {
                  childImageSharp {
                    fluid(maxWidth: 640, maxHeight: 400, quality: 90, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  relativePath
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <SEO title={product.name} description={product.meta_description} />
            <Container className="md:flex lg:mt-xl">
              <div className="max-w-xs mx-auto md:order-last md:max-w-none md:w-1/2 xl:w-5/12">
                {product.image &&
                  <Image fluid={data.images.edges.find(({node}) => node.relativePath === product.image).node.childImageSharp.fluid} alt={product.name} title={product.name} />
                }
              </div>
              <div className="gutter mt-lg md:w-1/2 xl:w-5/12 xl:ml-1/12">
                <header className="text-lg mb-lg">
                  <h1 className="my-0">{product.name}</h1>
                  <div>{product.description}</div>
                </header>
                <Features className="my-sm lg:text-lg">
                  {product.features && product.features.map((feature, index) => (
                    <Feature key={index}>{feature}</Feature>
                  ))}
                </Features>
                <AniLink className="accessory" to="/werkbankplatten">Weitere Werkbankplatten</AniLink>
              </div>
            </Container>
            <Container>
              <div className="my-screen-lg text-white md:flex md:flex-wrap xl:w-5/6 xl:mx-auto">
                {product.key_features && product.key_features.map(({icon, description}, index) => (
                  <div className="flex gutter my-xs md:w-1/2 md:block lg:w-1/3" key={index}>
                    <FontAwesomeIcon className="text-xl mr-sm text-brown md:my-sm md:text-4xl xl:text-5xl" icon={icon} fixedWidth />
                    <div className="max-w-sm lg:text-lg">
                      {description}
                    </div>
                  </div>
                ))}
              </div>
            </Container>
            <ContactUs slug="thomas-schenk" />
          </>
        )}
      />
    </Layout>
  )
}

export default ProductTemplate
